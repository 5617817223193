import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

@Injectable()
export class ScrollTopService {

    constructor(@Inject(WINDOW) private window: Window, 
        @Inject(PLATFORM_ID) private platformId,
        private router: Router
    ) { }

    setScrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events.subscribe((event: NavigationEnd) => {
                this.window.scroll(0, 0);
            });
        }
    }
}