import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ProfileModule } from './profile/profile.module';
import { HttpClientModule } from '@angular/common/http';
import { GalleryComponent } from './gallery/gallery/gallery.component';
import { GalleryModule } from './gallery/gallery.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
const routes: Routes = [
  {
    path: '',
    component: ProfileComponent
  },
  {
    path: 'galeria',
    component: GalleryComponent
  },
  {
    path: '**',
    component: ProfileComponent
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(routes, config),
    ProfileModule,
    GalleryModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
