import { Component, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(
    @Inject(WINDOW) private window: Window,
    private meta: Meta,
    private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'Töltsék pihenésüket, nyarlásukat a Barbara apartmanban, Balatonbogláron.A partközeli apartman a környék egyik legkomfortosabb, legolcsóbb szálláshelye. Keressen minket bizalommal!' },
      { name: 'author', content: 'barbara apartman' },
      // tslint:disable-next-line:max-line-length
      { name: 'keywords', content: 'Barbaraapartman, Balatonboglári nyaraló, Balatonboglár, Balatoni nyaralás, Balaton szállás, Balaton ingatlan 4 fő' }
    ]);
    this.setTitle('Barbara apartman');

  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  onActivate(event) {
    this.window.scroll(0, 0);
  }
}
