import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/config.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  sending: boolean;
  model: any = {};
  adminFormGroup = new FormGroup({
    // tslint:disable-next-line:max-line-length
    name: new FormControl(null, [Validators.required]),
    subject: new FormControl(null, [Validators.required]),
    // tslint:disable-next-line:max-line-length
    email: new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9_=]+([-+.\'][A-Za-z0-9_=]+)*@[A-Za-z0-9_]+([-.][A-Za-z0-9_]+)*\\.[A-Za-z0-9_]+([-.][A-Za-z0-9_]+)*')]),
    message: new FormControl(null, [Validators.required]),
  });
  contactusForm: FormGroup;
  constructor(private fb: FormBuilder, private contactService: ConfigService) { }

  ngOnInit() {
    this.contactusForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      message: [null, Validators.required],
    });

    this.sending = false;
  }




}
