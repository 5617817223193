import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window, 
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
  }
  navigateToGallery() {
    this.router.navigate(['galeria']);
    this.window.scroll(0, 0);
  }

}
