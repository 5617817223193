import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from '../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private meta: Meta,
    private title: Title
  ) {
    this.meta.addTags([
      { name: 'description', content: 'Töltsék pihenésüket, nyarlásukat a Barbara apartmanban, Balatonbogláron.A partközeli apartman a környék egyik legkomfortosabb, legolcsóbb szálláshelye. Keressen minket bizalommal!' },
      { name: 'author', content: 'barbara apartman' },
      // tslint:disable-next-line:max-line-length
      { name: 'keywords', content: 'Barbaraapartman, Balatonboglári nyaraló, Balatonboglár, Balatoni nyaralás, Balaton szállás, Balaton ingatlan 4 fő' }
    ]);
    this.setTitle('Barbara apartman');

  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

}
