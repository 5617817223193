import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { FooterComponent } from '../profile/footer/footer.component';
import { HeaderComponent } from '../profile/header/header.component';
import { IntroComponent } from '../profile/intro/intro.component';
import { SnotifyModule } from 'ng-snotify';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProfileModule } from '../profile/profile.module';
import { ScrollTopService } from '../scroll.service';



@NgModule({
  declarations: [GalleryComponent],
  imports: [
    CommonModule,
    ProfileModule,
    SnotifyModule,
    NgxSpinnerModule
  ],
  providers: [
    ScrollTopService
  ]
})
export class GalleryModule { }
