import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ProfileModule } from './profile/profile.module';
import { HttpClientModule } from '@angular/common/http';
import { GalleryComponent } from './gallery/gallery/gallery.component';
import { GalleryModule } from './gallery/gallery.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModule } from './app.module';
const routes: Routes = [
  {
    path: '',
    component: ProfileComponent
  },
  {
    path: 'galeria',
    component: GalleryComponent
  }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [
    
    RouterModule.forRoot(routes, config),
    ProfileModule,
    GalleryModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppModule,
    BrowserTransferStateModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
